import React from "react";
import { Link } from "react-router-dom";

export default function HomeButton(props) {
    return (
        <a href="#"
            onClick={props.action}
            className={"HomeButton HomeButton" + props.type}
        >
            <div className="HBText">
                <h1>{props.title}</h1>
                <h2>{props.subtitle}</h2>
            </div>
        </a>
    );
}