import React from "react"
import "./style.scss"

import HomeButton from "./HomeButton";

export default function Home(props) {
    return (
        <div className="Home">
            <div className="HomeBlock">
                <HomeButton type="NewGame" action={() => {
                    startGame() // eslint-disable-line
                } } title="New Game" subtitle="Start a new race!" />
            </div>
            <div className="HomeBlock">
                <HomeButton type="Leaderboard" title="Leaderboard" subtitle="See POG Players" />
            </div>
            <div className="HomeBlock">
                <HomeButton type="TrackBuilder" title="Track Builder" subtitle="Build your own tracks!" />
                <HomeButton type="App" title="Upgrade your ride" subtitle="Visit the web app!" />
            </div>
        </div>
    )
}