import './App.scss';
import logo from './logosmall.png';

import React, {useState, useEffect} from "react"
import {BrowserRouter as Router, Routes, Route, NavLink, useLocation} from "react-router-dom"
import Home from "./pages/Home"
import Settings from "./pages/Settings";

import bgvideo from './bgloop.webm'


function App() {
    return (
        <div className="App">

            <video className="BackgroundVideo" aria-hidden="true" playsInline={true} autoPlay={true} muted="" loop={true}>
                <source src={bgvideo} type="video/webm"></source>
            </video>

            <header className="Header">
                <div className="Logo">
                    <img src={logo} alt="Stream Racer"/>
                </div>
                <div className="Menu">
                    <ul>
                        <li>
                            <NavLink to="/" className={({isActive}) => isActive ? "active" : undefined}>HOME</NavLink>
                        </li>
                        <li>
                            <NavLink to="settings"
                                     className={({isActive}) => isActive ? "active" : undefined}>SETTINGS</NavLink>
                        </li>
                    </ul>
                </div>
            </header>
            <main className="Main">
                <Routes>
                    <Route path="/settings" element={<Settings/>}/>
                    <Route path="/" element={<Home/>}/>
                </Routes>
            </main>
            <footer className="Footer"></footer>
        </div>
    );
}

export default function AppWrapper() {
    return (

        <Router>
            <App/>
        </Router>

    )
}